import { useState, useEffect } from "react";

export const useTableSearch = ({ searchVal, retrieve,users },eventID) => {
  const [filteredData, setFilteredData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [searchIndex, setSearchIndex] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if(users && eventID){
      const eventUsers = users.filter(user => user.event_id === eventID)

      const crawl = (user, allValues) => {
        if (!allValues) allValues = [];
        for (var key in user) {
          if (typeof user[key] === "object") crawl(user[key], allValues);
          else allValues.push(user[key] + " ");
        }
        return allValues;
      };
        setOrigData([...eventUsers]);
        setFilteredData([...eventUsers]);
        const searchInd = eventUsers.map(user => {
          const allValues = crawl(user);
          return { allValues: allValues.toString() };
        });
        setSearchIndex(searchInd);
        setLoading(false);
    }
  }, [users,eventID]);

  useEffect(() => {
    if (searchVal) {
      const reqData = searchIndex.map((user, index) => {
        if (user.allValues.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0)
          return origData[index];
        return null;
      });
      setFilteredData(
        reqData.filter(user => {
          if (user) return true;
          return false;
        })
      );
    } else setFilteredData([...origData]);
  }, [searchVal, origData, searchIndex]);

  return { filteredData, loading};
};
