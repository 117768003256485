import React, { useState ,useEffect} from "react";
import { Table, Input,Modal,Upload,Form,message,Button ,Select,Space} from "antd";
import axios from "axios";
import { userColumns } from "./columns";
import { useTableSearch } from "./useTableSearch";
import data from "./test"

const { Option } = Select;
const {Dragger} = Upload
const { Search } = Input;

const onWalkinFinish = async (values,event_id) => {
  console.log('Success:', values);
  // Axios request to the with the form data
  await axios.post("https://checkin.momath.org/guest",{...values,event_id})

  
};

const onWalkinFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};




export default function App() {
  const [searchVal, setSearchVal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [walkinModalOpen, setWalkinModalOpen] = useState(false);
  const [ipadID, setIpadID] = useState("")

  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]) 
  const [invitee,setInvitee] = useState([])
  const [checkinUser, setCheckinUser] = useState(false);
  const [events, setEvents] = useState([])
  const [eventID,setEventID] = useState(9681)
  const [event_options,setEventOP] = useState([])


  
  const { filteredData, loading } = useTableSearch({
    searchVal,
    users

  },eventID);

  useEffect( ()=>{
    const submitCheckin = async () => {
      try {
        const {civi_id} = selectedUser
        const id = civi_id
        await axios.post(`https://checkin.momath.org/checkin?checkin_id=${civi_id}&ipad_id=${ipadID}`)
        const updatedGuestList = users.filter(user => user.civi_id !== id)
        setUsers(updatedGuestList)
        setSelectedUser(null)
        setIpadID("")
        setModalOpen(false)
        setCheckinUser(false)
      } catch (error) {
        
      }
    }
    if(checkinUser && selectedUser) submitCheckin()
    
  },[checkinUser,selectedUser,users])

  // useEffect(()=>{
  //   // grabs event data and populates event state
  //   if(!events.length && !event_options.length){
  //     const getEvents = async ()=>{
  //       let{data:events=data}= await axios("https://checkin.momath.org/events")
  //       events = events.filter(({event_id})=>(event_id === 9606 || event_id === "9606"))
  //       setEvents([...events])
  //       setEventOP(events.map(({event_id,event_name}) =>({label:event_name ,value:event_id})))
  //     } 
  //     getEvents()
  //   }
    
  // },[events,event_options])

  useEffect(()=>{
   const getusersWithID = async ()=>{
     if(eventID){
      const {data} = await axios(`https://checkin.momath.org/guests?event_id=${eventID}`)
      setUsers([...data])
      setInvitee([...data])
     }
   }
   getusersWithID()

  },[eventID])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const [user]= selectedRows
      setSelectedUser(user)
      setModalOpen(true)
    },
    getCheckboxProps: (record) => ({
      // disabled: record.checked_in === true,
      name: record.name,
    }),
  };

  return (
    <>
      {selectedUser &&<Modal
        title="Guest Conformation"
        style={{
          top: 20,
        }}
        open={modalOpen}
        onOk={() =>{ 
                      setCheckinUser(true) 
                      setModalOpen(false)
                    }}
        onCancel={() => setModalOpen(false)}
      >
        <div>{selectedUser.first_name + " " +selectedUser.last_name} {selectedUser.email}</div>
       <Input placeholder="Ipad number" defaultValue={ipadID} onChange={(e)=>{setIpadID(e.target.value)}}/>
      </Modal>}
     
      <Space style={{marginBottom:"20px",marginTop:"10px"}} size={"middle"}>
        <Button type="primary" onClick={()=>{setWalkinModalOpen(true)}}>Walk in Form</Button>
        {/* <Button type="primary" onClick={()=>{window.open(`https://checkin.momath.org/checkin?event_id=${eventID}`)}}>Download Event</Button> */}
      </Space>
      <Modal title="Add Guest Form" 
        style={{top:20}}
        open={walkinModalOpen}
        onOk={()=>{
          setWalkinModalOpen(false)
        }}
        onCancel={()=>{
          setWalkinModalOpen(false)
        }} 
      > 
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600,display:"grid" }}
        initialValues={{ remember: false }}
        onFinish={(values)=>{onWalkinFinish(values,eventID)}}
        onFinishFailed={onWalkinFinishFailed}
        autoComplete="off"
      >
      <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: 'Please input First name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: 'Please input last name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input email' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item 
        label="Guest Status"
        name="gueststatus"
        rules={[{required:true , message:"Please set where a guest"}]}
      
      >
        <Select
          placeholder="Set Guest Status"
          onChange={()=>{console.log("a change")}}
        >
          <Option value="walkin">Walk in</Option>
          <Option value="pluseone">Pluse One</Option>

        </Select>

      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues,currentValues)=> currentValues.gueststatus ==="pluseone"}

      >
        {({ getFieldValue }) =>
          getFieldValue('gueststatus') === 'pluseone' ? (
            <Form.Item name="civi_id" label="Invitee" rules={[{ required: true }]}>
              <Select
                options={invitee.map(({first_name,last_name,civi_id}) =>({label:`${first_name} ${last_name}` ,value:civi_id}))}
              />
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item
        style={{justifySelf:"center"}}
      >
        <Button type="primary" htmlType="submit">
          Add Guest
        </Button>
      </Form.Item>
      </Form>
      
      </Modal>

      <Search
        onChange={e => setSearchVal(e.target.value)}
        placeholder="Search"
        enterButton
        style={{ position: "sticky", top: "0", left: "0" }}
      />
      <br /> <br />
      <Table
        rowKey={"civi_id"}
        dataSource={filteredData.sort(function(a, b) {
          return a.last_name.localeCompare(b.last_name);
       })}
        rowSelection={{type:"radio",...rowSelection}}
        columns={userColumns}
        loading={loading}
        pagination={true}
      />
    </>
  );
}