export const userColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name"
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name"
    },
    {
      title: "Table",
      dataIndex: "assigned_table",
      key: "assigned_table"
    },

    {
      title:" Email",
      dataIndex:"email",
      key:"email"
    },
    {
      title: "ID",
      dataIndex: "civi_id",
      key: "civi_id"
    },
    // {
    //   title: "Ipad",
    //   dataIndex: "ipads",
    //   key: "ipads"
    // },
  ];
  